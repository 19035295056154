import React from 'react';
import { boxboatColors } from './boxboatColors';
import { makeStyles } from '@material-ui/core';

const classes: Record<string, any> = {};
Object.keys(boxboatColors).map(color => {
  for (let weight in (boxboatColors as any)[color]) {
    classes[`bg-${color}-${weight}`] = {
      'background-color': (boxboatColors as any)[color][weight],
      'text-align': 'center',
    };
  }
});
const useStyles = makeStyles({
  ...classes,
});

const BoxboatColorPalette = () => {
  const classes = useStyles();
  const rows = [];
  for (let c in classes) {
    rows.push(<div className={classes[c]}>{c}</div>);
  }
  return <div>{rows}</div>;
};

export default BoxboatColorPalette;
