import React from 'react';
import { makeStyles } from '@material-ui/core';
import logoFull from '../../themes/boxboat-logo-full.png';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 36,
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.img} src={logoFull} />;
};

export default LogoFull;
