import React from 'react';
import { makeStyles } from '@material-ui/core';
import icon from '../../themes/boxboat-logo-icon.png';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 30,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return <img className={classes.img} src={icon} />;
};

export default LogoIcon;
