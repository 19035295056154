import {
  palettes,
  createBaseThemeOptions,
  createUnifiedTheme,
  PageTheme,
  genPageTheme,
  shapes,
  pageTheme as defaultPageThemes,
} from '@backstage/theme';
import { boxboatColors } from './boxboatColors'

// const backstagePageThemeColorVariants = {
//   darkGrey: ['#171717', '#383838'],
//   marineBlue: ['#006D8F', '#0049A1'],
//   veryBlue: ['#0027AF', '#270094'],
//   rubyRed: ['#98002B', '#8D1134'],
//   toastyOrange: ['#BE2200', '#A41D00'],
//   purpleSky: ['#8912CA', '#3E00EA'],
//   eveningSea: ['#00FFF2', '#035355'],
//   teal: ['#005B4B'],
//   pinkSea: ['#C8077A', '#C2297D'],
//   greens: ['#4BB8A5', '#187656'],
// };

const stormPetrelColors = {
  red: '#9e7276',
  orange: '#9c7760',
  yellow: '#8a7c55',
  green: '#5b8a55',
  teal: '#548587',
  blue: '#5f8299',
  purple: '#86779e',
  pink: '#997383',
  black: '#1d252b',
};

/**
 * Page Themes define the page header banner pattern and color.
 * It also defines the template component color
 * Themes are stored as a dictionary: Record<string, PageTheme> where string is a themeID (ie, documentation, home, apis, etc).
 * See {@link defaultPageThemes} for default themes.
 *
 * @public
 */
const boxboatPageTheme: Record<string, PageTheme> = {
  ...defaultPageThemes,
  home: genPageTheme({
    colors: [stormPetrelColors.blue],
    shape: shapes.wave,
  }),
  apis: genPageTheme({
    colors: [stormPetrelColors.green],
    shape: shapes.wave,
  }),
  documentation: genPageTheme({
    colors: [stormPetrelColors.orange],
    shape: shapes.wave2,
  }),
  tool: genPageTheme({
    colors: [stormPetrelColors.teal],
    shape: shapes.round,
  }),
  service: genPageTheme({
    colors: [stormPetrelColors.yellow],
    shape: shapes.wave,
  }),
  database: genPageTheme({
    colors: [stormPetrelColors.green],
    shape: shapes.wave,
  }),
  compute: genPageTheme({
    colors: [stormPetrelColors.red],
    shape: shapes.wave,
  }),
  configuration: genPageTheme({
    colors: [stormPetrelColors.purple],
    shape: shapes.wave,
  }),
  github: genPageTheme({
    colors: [stormPetrelColors.black],
    shape: shapes.wave,
  }),
};

export const boxboatThemes = {
  light: createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: stormPetrelColors.blue,
        },
        navigation: {
          background: boxboatColors.primary[800],
          color: boxboatColors.gray[700],
          indicator: stormPetrelColors.pink,
          selectedColor: '#000000',
          navItem: {
            hoverBackground: boxboatColors.gray[1100],
          },
          submenu: {
            background: boxboatColors.gray[800],
          },
        },
      },
    }),
    // Component Overrides go here
    components: {},
    pageTheme: boxboatPageTheme,
    defaultPageTheme: 'home',
  }),
  dark: createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.dark,
        background: {
          default: boxboatColors.gray[1000],
          paper: boxboatColors.gray[900],
        },
        primary: {
          main: boxboatColors.primary[400],
        },
        navigation: {
          background: boxboatColors.gray[900],
          color: boxboatColors.gray[100],
          indicator: boxboatColors.green[300],
          selectedColor: '#FFFFFF',
          navItem: {
            hoverBackground: boxboatColors.gray[500],
          },
          submenu: {
            background: boxboatColors.gray[800],
          },
        },
      },
    }),
    // Component Overrides go here
    components: {},
    pageTheme: boxboatPageTheme,
    defaultPageTheme: 'home',
  }),
};

