type BoxboatColorType = {
  primary: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
  };
  gray: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
    1100: string;
  };
  teal: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
  };
  yellow: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
  };
  green: {
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
  };
};

export const boxboatColors: BoxboatColorType = {
  primary: {
    100: '#83C8EE', // this one is from the style guide
    200: '#71b5df',
    300: '#5397c9',
    400: '#337DB7', // this one is from the style guide
    500: '#2e72a9',
    600: '#266293',
    700: '#1E5481', // this one is from the style guide
    800: '#f8f8f8'
  },
  gray: {
    100: '#C1C6C8', // this one is from the style guide
    200: '#b7bcbe',
    300: '#acb1b3',
    400: '#a1a5a7',
    500: '#95999b',
    600: '#888B8D', // this one is from the style guide
    700: '#7a7c7e',
    800: '#696c6d',
    900: '#4a4c4d',
    1000: '#333333',
    1100: '#ebebeb'
  },
  teal: {
    100: '#c8ecee',
    200: '#a5e2e6',
    300: '#79d7dd',
    400: '#2DCCD3', // this one is from the style guide
    500: '#28b6bd',
    600: '#239ea3',
    700: '#1c8185',
  },
  yellow: {
    100: '#edf3c6',
    200: '#e4eca1',
    300: '#dae672',
    400: '#D0DF00', // this one is from the style guide
    500: '#bac700',
    600: '#a1ad00',
    700: '#848d00',
  },
  green: {
    100: '#c6e5cc',
    200: '#a1d7ac',
    300: '#72c786',
    400: '#00B74F', // this one is from the style guide
    500: '#00a447',
    600: '#008e3d',
    700: '#007432',
  },
};
